import { AbstractAuthError } from './../abstracts/AbstractAuthError'
import CREATE_TOKEN_ERRORS from '@/enums/createTokenErrors.enum'

export class Credentials extends AbstractAuthError {

  isMatchError () {
    return this.error_description === CREATE_TOKEN_ERRORS.CREDENTIALS
  }

  getMessages () {
    return ['PASSWORD.INVALID-PASSWORD']
  }
}
