const CREATE_TOKEN_ERRORS = {
  CAPTCHA_CHALLENGE: 'require_captcha_challenge',
  BLOCKED_USER: 'user_blocked',
  BLOCKED_CAPTCHA_V3: 'invalid_captcha_token_or_response',
  CREDENTIALS: 'invalid_username_or_password',
  MFA_CREATE: 'mfa is required',
  MFA_CODE_REQUIRED: 'mfa code is required',
  MFA_NOT_ALLOWED: 'client does not allow mfa user'
}

export default CREATE_TOKEN_ERRORS
