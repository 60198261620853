<template lang="html">
  <div class="eu-ease-in">
    <div
      v-if="showCaptchav2"
      class="eu-align_center eu-pt_3"
    >
      <p class="emd-input-feedback eu-mb_2">{{ $t('PASSWORD.CAPTCHA') }}</p>
      <vue-recaptcha
        :loadRecaptchaScript="true"
        :sitekey="recaptchaKey"
        @verify="validateCaptcha"
        @expire="resetRecaptcha"
        :hl="$i18n.locale"
        :theme="pref.appearance.mode"
        ref="vueRecaptcha"
        class="captcha-container g-recaptcha"
      />
      <p
        class="emd-input-feedback eu-align_center _state_error"
        v-if="showCaptchaFeedback"
      >
        {{ $t('PASSWORD.REQUIRED-CAPTCHA') }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

const vueRecaptcha = () => import('vue-recaptcha')

export default {
  name: 'Recaptchav2Step',
  components: {
    vueRecaptcha
  },
  props: {
    isLoading: {
      type: Boolean,
    },
    showCaptchaFeedback: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'showCaptchav2',
      'pref'
    ]),
    recaptchaKey () {
      return process.env.VUE_APP_RECAPTCHA_KEY_V_TWO
    }
  },
  data () {
    return {
      passwordIsText: false,
      inputedPassword: undefined,
      passwordValidityState: {
        valid: true,
        tooShort: false
      }
    }
  },
  methods: {
    validateCaptcha (response) {
      this.$emit('update:showCaptchaFeedback', false)
      this.$emit('update:recaptcha', response)
    },
    resetRecaptcha () {
      this.$refs.vueRecaptcha.reset()
      this.$emit('resetCaptcha')
    }
  }
}
</script>

<style>
.captcha-container {
  width: 304px;
  margin: 0 auto;
  overflow: hidden;
}
</style>
