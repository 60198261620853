export function inputValidate (value, element) {
  return {
    tooShort: element.minLength > value.length,
    valueMissing: element.required && value.length === 0,
    valid:
      value.length >= element.minLength &&
      element.required &&
      value.length !== 0
  }
}
