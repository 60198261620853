import CREATE_TOKEN_ERRORS from '@/enums/createTokenErrors.enum'
import {
  AbstractAuthErrorMfaCodeRequired
} from './../abstracts/AbstractAuthErrorMfaCodeRequired'

export class MfaCreate extends AbstractAuthErrorMfaCodeRequired {

  isMatchError () {
    return this.error_description === CREATE_TOKEN_ERRORS.MFA_CREATE
  }

  resolve () {
    this.setSessionId()

    const email = this.payload?.username
    this.$store.dispatch('CREATE_TFA_DATA', { email: email })
    this.$router.push({ name: 'twofaactivation' })
  }
}
