import { AbstractAuthError } from './../abstracts/AbstractAuthError'


export class DefaultError extends AbstractAuthError {

  getMessages () {
    const notifications = this.error?.body?.notifications

    if(notifications && Object.keys(notifications)?.length) {
      return Object.values(notifications)
    }

    const defaultMessage = this.payload?.code ? 'TWOFA-CODE.ALERT-CHECK-THE-CODE' : 'PASSWORD.GENERIC-ERROR'
    return [defaultMessage]
  }
}
