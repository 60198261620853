import router from '@/router'
import store from '@/store'

export class AbstractAuthError {
  constructor (payload, error) {
    this.$router = router
    this.$store = store

    this.payload = payload
    this.email = payload?.username
    this.error = error
    this.error_description = error?.body?.error_description
  }

  isMatchError () {
    throw new Error('Method isMatchError() must be implemented.')
  }

  resolve () {
    // Default function is empty
  }

  getMessages () {
    return []
  }

  redirectToSignin () {
    const currentPath = window.location.pathname
    const isSigninPage = currentPath === '/signin'

    if (isSigninPage) return

    const email = this.payload?.username
    const queryParams = email ? { email: email } : {}
    this.$router.push({
      name: 'signin',
      query: queryParams
    })
  }
}
