import { env, ProfileInstance } from '@/store'

const mfa = {
  state: {
    tfaLoginBody: {},
    userTwoFactorAuthentication: {},
    tfaPhone: { code: '', phone: '' },
    tfaMaskedPhone: '',
  },
  mutations: {
    SET_TFA_LOGIN_BODY (state, body) {
      state.tfaLoginBody = {...state.tfaLoginBody, ...body}
    },
    SET_TFA_MASKED_PHONE (state, payload) {
      state.tfaMaskedPhone = payload
      sessionStorage.setItem(`${env}mp_maskedPhone`, payload)
    },
    SET_TFA_PHONE (state, payload) {
      state.tfaPhone = payload
      sessionStorage.setItem(`${env}mp_phone`, JSON.stringify(state.tfaPhone))
    },
    GET_MASKED_PHONE (state) {
      const maskedPhoneInStorage = sessionStorage.getItem(
        `${env}mp_maskedPhone`
      )
      if (maskedPhoneInStorage) {
        state.tfaMaskedPhone = maskedPhoneInStorage
      }
    },
    GET_TFA_PHONE (state) {
      const phoneInStorage = sessionStorage.getItem(`${env}mp_phone`)
      if (phoneInStorage) {
        state.tfaPhone = JSON.parse(phoneInStorage)
      }
    },
    SET_USER_TFA (state, tfa) {
      state.userTwoFactorAuthentication = {...state.userTwoFactorAuthentication, ...tfa}
      sessionStorage.setItem(
        `${env}mp_mfa`,
        JSON.stringify(state.userTwoFactorAuthentication)
      )
    },
    GET_USER_TFA (state) {
      const mfaCookie = sessionStorage.getItem(`${env}mp_mfa`)
      if (mfaCookie) {
        const mfaJson = JSON.parse(mfaCookie)
        state.userTwoFactorAuthentication = {...state.userTwoFactorAuthentication, ...mfaJson}
      }
    },
  },
  getters: {
    tfaLoginBody (state) {
      return state.tfaLoginBody
    },
    userTwoFactorAuthentication (state) {
      return state.userTwoFactorAuthentication
    },
    tfaPhone (state) {
      return state.tfaPhone
    },
    tfaMaskedPhone (state) {
      return state.tfaMaskedPhone
    },
  },
  actions:  {
    CREATE_TFA_DATA ({ commit }, tfa) {
      commit('SET_USER_TFA', tfa)
    },
    SET_LOGIN_DATA_TFA ({ commit }, body) {
      commit('SET_TFA_LOGIN_BODY', body)
    },
    MFA_CREATE (context) {
      const token = context.getters.token()
      if (token) {
        return ProfileInstance.me.createUserMFA(
          context.state.userTwoFactorAuthentication
        )
      }


      const { email } = context.state.userTwoFactorAuthentication
      const body = { ...context.state.userTwoFactorAuthentication }
      delete body.email
      return ProfileInstance.mfa.createUserMFA(email, body)
    },
    MFA_ACTIVATE (context, code) {
      const token = context.getters.token()
      if (token) {
        return ProfileInstance.me.getActiveUserMFA(code)
      }

      const { email } = context.state.userTwoFactorAuthentication
      return ProfileInstance.mfa.getActiveUserMFA(email, code)
    },
    MFA_SEND ({ getters }) {
      const token = getters.token()
      if (token) {
        return ProfileInstance.me.sendUserMFA()
      }

      const email = getters.userEmail
      return ProfileInstance.mfa.sendUserMFA(email)
    },
    MFA_RESET ({ getters }, data) {
      const email = getters.userEmail
      return ProfileInstance.mfa.resetMFaUser(email, data)
    },
  }
}
export default mfa
