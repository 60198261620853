<template>
  <footer>
    <ul class="emd-list _inline _space_base eu-align_center eu-align_right_md">
      <li v-if="componentIsEnabled('footer', 'privacySecurity')">
        <a
          class="emd-text _size_small _color_default"
          :href="urlPrivacySecurity"
          target="_blank"
          tabindex="-2"
          >{{ $t('FOOTER.PRIVACY') }}</a
        >
      </li>
      <li v-if="componentIsEnabled('footer', 'help')">
        <a
          class="emd-text _size_small _color_default"
          :href="urlHelp"
          target="_blank"
          tabindex="-2"
          >{{ $t('FOOTER.HELP') }}</a
        >
      </li>
    </ul>
  </footer>
</template>

<script>

export default {
  name: 'EmdFooterExternal',
  computed: {
    urlPrivacySecurity () {
      return process.env.VUE_APP_URL_PRIVACY_SECURITY
    },
    urlHelp () {
      return process.env.VUE_APP_URL_HELP
    }
  },
  methods: {
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  }
}
</script>
