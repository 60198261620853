const whitelabel = {
  state: {
    whitelabel: {
      enabled: false,
      theme: 'pagarme',
      url: process.env.VUE_APP_BASE_URL,
      domain: '.pagar.me'
    }
  },
  getters: {
    whitelabel (state) {
      return state.whitelabel
    }
  },
  mutations: {
    updateWhitelabel (state, value) {
      state.whitelabel = value
    }
  },
  actions: {
    updateWhitelabel ({ commit }, value) {
      commit('updateWhitelabel', value)
    },
    loadEmeraldByTheme ({ commit }, theme) {
      const emerald = () =>
        import('emerald-workbench/dist/emerald.pagarme.min.css')
      commit('updateWhitelabel', { theme: theme })
      emerald()
    }
  }
}
export default whitelabel
