import { MFA_TYPES } from '@/enums/mfaTypes.enum'
import {
  AbstractAuthErrorMfaCodeRequired
} from './../abstracts/AbstractAuthErrorMfaCodeRequired'
import CREATE_TOKEN_ERRORS from '@/enums/createTokenErrors.enum'

export class MfaCodeRequiredSMS extends AbstractAuthErrorMfaCodeRequired {

  isMatchError () {
    return this.error_description === CREATE_TOKEN_ERRORS.MFA_CODE_REQUIRED &&
      this.type === MFA_TYPES.SMS
  }

  resolve () {
    this.setSessionId()
    this.$store.dispatch('CREATE_TFA_DATA', {
      phone_number: this.error_response?.phone_number
    })
    this.$router.push({ name: 'twofaloginphonecode' })
  }
}
