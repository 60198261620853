<template lang="html">
  <div>
    <emd-form-group
      :state="passwordErrorState ? 'error' : ''"
      :label="$t('PASSWORD.LABEL')"
      labelFor="password"
      :feedbacks="[
        {
          msg: $t('PASSWORD.ERROR-TEXT'),
          condition: passwordValidityState.tooShort
        }
      ]"
    >
      <div class="emd-input-group">
        <emd-input
          data-cy="signin-password"
          name="password"
          class="fs-exclude"
          type="password"
          ref="passwordInput"
          v-model="inputedPassword"
          :placeholder="$t('LOGIN.PASSWORD-PLACEHOLDER')"
          :class="{ 'eu-shake': passwordErrorState }"
          :minLength="6"
          :onInput="formIsValid"
          :disabled="isLoading"
          autocomplete="off"
          required
        />
        <a
          class="emd-input-icon__link _align_right emd-link _color_primary"
          href="javascript:void(0)"
          tabindex="-1"
          @click="showPassword($refs.passwordInput.$el)"
        >
          <i
            class="uil uil-eye-slash"
            v-if="passwordIsText"
          ></i>
          <i
            class="uil uil-eye"
            v-else
          ></i>
        </a>
      </div>
      <p
        class="emd-input-feedback"
        v-if="componentIsEnabled('input', 'help')"
      >
        {{ $t('PASSWORD.WHITELABEL-ERROR') }}
        <a
          class="emd-link"
          href="mailto:ie.opsgarage@jpmorgan.com"
          >ie.opsgarage@jpmorgan.com</a
        >
      </p>
    </emd-form-group>
  </div>
</template>
<script>
import { EmdInput, EmdFormGroup } from 'emerald-vue/src/components'

export default {
  name: 'PasswordStep',
  components: {
    EmdInput,
    EmdFormGroup
  },
  computed: {
    pref () {
      return this.$store.getters.pref
    }
  },
  props: {
    passwordErrorState: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    password: {
      type: String || undefined,
      required: true
    },
    passwordIsValid: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    'update:password',
    'update:isLoading',
    'update:passwordIsValid',
    'update:passwordErrorState'
  ],
  data () {
    return {
      passwordIsText: false,
      inputedPassword: undefined,
      passwordValidityState: {
        valid: true,
        tooShort: false
      }
    }
  },
  methods: {
    showPassword (input) {
      if (input.type === 'password') {
        input.type = 'text'
        this.passwordIsText = true
      } else {
        input.type = 'password'
        this.passwordIsText = false
      }
    },
    formIsValid (e) {
      this.passwordValidityState.valid = e.target.validity.valid
      this.passwordValidityState.tooShort = e.target.validity.tooShort
      this.$emit(
        'update:passwordIsValid',
        this.$parent.$refs.handleForm.checkValidity()
      )
      this.$emit('update:password', this.inputedPassword)
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  },
  mounted () {
    this.$refs.passwordInput.$el.focus()
  }
}
</script>

<style>
.captcha-container {
  width: 304px;
  margin: 0 auto;
  overflow: hidden;
}
</style>
