const getRedirectApp = (nextURL, redirectForNewUrlPilot = false) => {
  const envVariables = process.env

  const appPilotValid = [
    'pilot',
    'sandbox',
    'pilot_local',
    'pilot_old',
    'sandbox_old',
    'pilot_staging',
    'sandbox_staging'
  ]

  const redirectForNewUrl = JSON.parse(String(redirectForNewUrlPilot))

  const app =
    appPilotValid.find(app => {
      const appURL = envVariables[`VUE_APP_${app.toUpperCase()}_URL`]
      const appURLHostname = appURL ? appURL.replace(/^https?:\/\//, '') : ''

      return appURLHostname === nextURL.hostname
    }) ?? ''

  return redirectForNewUrl === true ? app.replace('_old', '') : app
}

export default getRedirectApp
