import { AbstractAuthError } from './../abstracts/AbstractAuthError'
import HTTP_STATUS_CODE from '@/enums/httpStatusCode.enum'

export class ToManyRequest extends AbstractAuthError {
  isMatchError () {
    return this.error.status === HTTP_STATUS_CODE.TOO_MANY_REQUESTS
  }

  getMessages () {
    return ['GENERAL-ERROR.TOO-MANY-REQUESTS']
  }
}
