import { MFA_TYPES } from '@/enums/mfaTypes.enum'
import {
  AbstractAuthErrorMfaCodeRequired
} from './../abstracts/AbstractAuthErrorMfaCodeRequired'
import CREATE_TOKEN_ERRORS from '@/enums/createTokenErrors.enum'

export class MfaCodeRequiredAPP extends AbstractAuthErrorMfaCodeRequired {

  isMatchError () {
    return this.error_description === CREATE_TOKEN_ERRORS.MFA_CODE_REQUIRED &&
      this.type === MFA_TYPES.APP
  }

  resolve () {
    this.setSessionId()
    this.$router.push({ name: 'twofaloginappcode' })
  }
}
