const modal = {
  state: {
    resetSuccess: false,
    resetCodeConfirmCopy: {
      open: false,
      returnForCopy: false
    },
  },
  mutations: {
    updateResetSuccess (state) {
      state.resetSuccess = !state.resetSuccess
    },
    updateResetCodeConfirmCopy (state) {
      state.resetCodeConfirmCopy.open = !state.resetCodeConfirmCopy.open
    },
    userReturnForCopy (state) {
      state.resetCodeConfirmCopy.returnForCopy = true
    }
  }
}
export default modal
