import { env } from '@/store'

const twofaErrors = {
  createIsRequired: 'mfa is required',
  codeRequired: 'mfa code is required'
}

const twofaRequired = {
  methods: {
    isTfaError (description = '') {
      return Object.values(twofaErrors).includes(description)
    },
    isTfaCreateRequired (description = '') {
      return description === twofaErrors.createIsRequired
    },
    isTfaCodeRequired (description = '') {
      return  description === twofaErrors.codeRequired
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    },
    tfaSetSessionId (sessionId) {
      const fiveMinutes = 300000
      const expires = Date.now() + fiveMinutes
      const sessionObject = JSON.stringify({sessionId, expires})
      sessionStorage.setItem(`${env}mp_si`, sessionObject)
    },
    tfaCreateWithToken (userHasTfa) {
      const tfaAllowedUser = this.componentIsEnabled('tfa', 'tfaConfig')

      if (!userHasTfa && tfaAllowedUser) {
        this.$store.dispatch('CREATE_TFA_DATA', { email: this.email })
        this.$router.push({ name: 'twofaactivation' })
      } else {
        this.$store.dispatch('UPDATE_SIGN_LOADING', true)
        this.$store.dispatch('GO_TO_REDIRECT_URL')
      }
    },
    tfaCreate (tfaError) {
      const tfaAllowedUser = this.componentIsEnabled('tfa', 'tfaConfig')

      if (tfaError?.response?.session_id && tfaAllowedUser) {
        this.tfaSetSessionId(tfaError.response.session_id)
        this.$store.dispatch('CREATE_TFA_DATA', { email: this.email })
        this.$router.push({ name: 'twofaactivation' })
      } else {
        this.$store.dispatch('UPDATE_SIGN_LOADING', true)
        this.$store.dispatch('GO_TO_REDIRECT_URL')
      }
    },
    tfaRedirect (tfaError) {
      const type = tfaError.response.type

      if (tfaError && type === 'Sms') {
        this.$store.dispatch('CREATE_TFA_DATA', {
          phone_number: tfaError.response.phone_number
        })
        this.tfaSetSessionId(tfaError.response.session_id)
        this.$router.push({ name: 'twofaloginphonecode' })
      } else if (tfaError && type === 'App') {
        this.tfaSetSessionId(tfaError.response.session_id)
        this.$router.push({ name: 'twofaloginappcode' })
      }
    }
  }
}

export default twofaRequired
