import { AbstractAuthError } from './AbstractAuthError'
import { env } from '@/store'

export class AbstractAuthErrorMfaCodeRequired extends AbstractAuthError {
  constructor (payload, error) {
    super(payload, error)
    this.error_response = error?.body?.response
    this.type = this.error_response?.type?.toUpperCase()
  }

  setSessionId () {
    const sessionId = this.error_response?.session_id

    if (sessionId) {
      const sessionObject = JSON.stringify({ sessionId })
      sessionStorage.setItem(`${env}mp_si`, sessionObject)
    }
  }
}
