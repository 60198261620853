const components = {
  logo: {
    pagarme: ['pagarme'],
    jpmorgan: ['jpmorgan']
  },
  globalError: {
    logo: ['pagarme']
  },
  input: {
    help: ['jpmorgan']
  },
  footer: {
    switchThemeMode: ['pagarme'],
    help: ['pagarme'],
    privacySecurity: ['jpmorgan'],
    signup: ['pagarme']
  },
  tfa: {
    tfaConfig: ['pagarme'],
    tfaLogin: ['pagarme']
  }
}

export default components
