const captcha = {
  state: {
    showCaptchav2: false,
    requiredCaptchav2: false,
  },
  mutations: {
    SHOW_CAPTCHA (state, value) {
      state.showCaptchav2 = value
    },
    SET_REQUIRED_CAPTCHA_V2 (state, value) {
      state.requiredCaptchav2 = value
    },
  },
  getters: {
    showCaptchav2 ({ requiredCaptchav2 }) {
      const isRecaptchaV2 = Number(process.env.VUE_APP_RECAPTCHA_VERSION) === 2

      return isRecaptchaV2 || requiredCaptchav2
    },
  },
  actions:  {
    SET_SHOW_CAPTCHA ({ commit }, value) {
      commit('SHOW_CAPTCHA', value)
    },
    SET_REQUIRED_CAPTCHA_V2 ({ commit }, value) {
      commit('SET_REQUIRED_CAPTCHA_V2', value)
    },
  }
}
export default captcha
