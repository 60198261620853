import { env } from '@/store'

export function validitySession () {
  try {
    const session = sessionStorage.getItem(`${env}mp_si`)
    const sessionObject = JSON.parse(session)

    // Se não for valido, remove e retorna undefined
    if (!sessionObject?.sessionId) {
      sessionStorage.removeItem(`${env}mp_si`)
      return undefined
    }

    return session
  } catch(_err) {
    return undefined
  }
}
