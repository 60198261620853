import { AbstractAuthError } from './../abstracts/AbstractAuthError'
import CREATE_TOKEN_ERRORS from '@/enums/createTokenErrors.enum'

export class CaptchaChallenge extends AbstractAuthError {

  isMatchError () {
    return this.error_description === CREATE_TOKEN_ERRORS.CAPTCHA_CHALLENGE
  }

  resolve () {
    this.$store.dispatch('SET_REQUIRED_CAPTCHA_V2', true)
    this.redirectToSignin()
  }

  getMessages () {
    return ['GENERAL-ERROR.CAPTCHA-CHALLENGE']
  }
}
