import Cookies from 'js-cookie'
import { env, IDInstance, ProfileInstance } from '@/store'

const redirect = {
  state: {
    redirectApp: '',
    redirectNext: {
      app: '',
      path: '',
      search: ''
    }
  },
  mutations: {
    SET_REDIRECT_APP (state, app) {
      state.redirectApp = app
    },
    SET_REDIRECT_NEXT (state, redirectNext) {
      state.redirectNext = redirectNext
    }
  },
  actions: {
    GO_TO_REDIRECT_URL () {
      IDInstance.operation
        .getRedirectUrl(process.env)
        .then(res => window.location.assign(res))
        .catch(() =>
          window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
        )
    },
    SET_REDIRECT_URL ({ _dispatch }, app) {
      IDInstance.operation.setRedirectUrl(app)
    },
    REDIRECT_MAKER (context) {
      const statusCompanyValid = [
        'active',
        'pending_activation',
        'pending_risk_analysis',
        'pending_data',
        'pending_afiliation',
        'temporary'
      ]
      const redirectNext = context.state.redirectNext
      const redirectNextApp = redirectNext?.app

      const itsPilotRedirect = ({ merchants, companies }) => {
        const hasJustACompany = !merchants.length && companies.length === 1
        const aCompany = companies.length === 1 && companies[0]
        const canRedirectNextApp = !!redirectNextApp && aCompany

        if (canRedirectNextApp) {
          const appPilotPrd = ['pilot', 'pilot_local', 'pilot_staging', 'pilot_old']
          const appPilotSdx = ['sandbox', 'sandbox_staging', 'sandbox_old']
          const companyStatus = aCompany.status.toLowerCase()

          const nextAppIsValid =
            appPilotPrd.includes(redirectNextApp) && companyStatus === 'active'

          const nextAppIsValidSdx =
            appPilotSdx.includes(redirectNextApp) &&
            statusCompanyValid.includes(companyStatus)

          return [nextAppIsValid, nextAppIsValidSdx].some(v => v)
        }

        return [
          hasJustACompany,
          aCompany && aCompany.status.toLowerCase() === 'active'
        ].every(v => v)
      }

      const isDashRedirect = ({ merchants, companies }) => {
        const hasJustAMerchant = merchants.length === 1 && !companies.length
        const hasJustMerchants = !companies.length && merchants.length >= 2
        const hasACompanyAndMerchants =
          merchants.length && companies.length === 1
        const hasMerchantDefault = merchants.find(mer => mer.is_default)
        const hasACompanyAndInvalid =
          !redirectNextApp &&
          !statusCompanyValid.includes(companies[0]?.status.toLowerCase())

        return [
          hasJustAMerchant,
          hasJustMerchants && hasMerchantDefault,
          hasACompanyAndMerchants && hasACompanyAndInvalid && hasMerchantDefault
        ].some(v => !!v)
      }

      return ProfileInstance.me.permissions().then((res) => {
        // if has previous redirect cookie
        const redirectCookie = Cookies.get(`${env}mp_rd`)
        let redirect = {}

        // Fills the redirect
        if (redirectCookie) {
          redirect = JSON.parse(atob(redirectCookie))
        } else if (itsPilotRedirect(res)) {
          const company = res.companies[0]
          const companyEnv =
            company && company?.status.toLowerCase() === 'active'
              ? 'pilot'
              : 'sandbox'

          redirect.app = redirectNextApp || companyEnv
          redirect.path = '#/account/login'
          redirect.search = `?companyid=${company?.id}`

          if (redirectNextApp) {
            redirect.path = `${redirectNext.path}#/account/login`
          }
        } else if (isDashRedirect(res)) {
          redirect.app = 'dash'
        } else {
          redirect.app = 'profile'
          redirect.path = '/enterprises'
        }

        context.dispatch('SET_REDIRECT_URL', redirect)
      })
    }
  }
}
export default redirect
