import {
  CaptchaChallenge,
  Credentials,
  DefaultError,
  MfaCodeRequiredAPP,
  MfaCodeRequiredSMS,
  MfaCreate,
  MfaNotAllowed,
  ToManyRequest,
  UserBlocked,
  CaptchaV3
} from './AuthErrors'

export class AuthErrorHandler {
  constructor (error, payload = {}) {
    this.error = error
    this.payload = payload
  }

  findClassError () {
    const errors = [
      CaptchaChallenge,
      UserBlocked,
      Credentials,
      MfaCodeRequiredAPP,
      MfaCodeRequiredSMS,
      MfaCreate,
      MfaNotAllowed,
      CaptchaV3,
      ToManyRequest
    ]

    for (const ErrorClass of errors) {
      const handledError = new ErrorClass(this.payload, this.error)
      if (handledError.isMatchError()) {
        return handledError
      }
    }

    return null
  }

  getInstance () {
    const handledError = this.findClassError()
    return handledError || new DefaultError(this.payload, this.error)
  }
}
