import { AbstractAuthError } from './../abstracts/AbstractAuthError'
import CREATE_TOKEN_ERRORS from '@/enums/createTokenErrors.enum'

export class CaptchaV3 extends AbstractAuthError {

  isMatchError () {
    return this.error_description === CREATE_TOKEN_ERRORS.BLOCKED_CAPTCHA_V3
  }

  resolve () {
    this.redirectToSignin()
  }

  getMessages () {
    return ['PASSWORD.GENERIC-ERROR']
  }
}
