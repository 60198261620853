const MFA_TYPES_CODES = {
  SMS: 0,
  APP: 1
}

const MFA_TYPES = Object.fromEntries(
  Object.keys(MFA_TYPES_CODES).map(key => [key, key])
)

export { MFA_TYPES, MFA_TYPES_CODES }
