import HTTP_STATUS_CODE from '@/enums/httpStatusCode.enum'

export class InternalErrorHandler {
  constructor (error, defaultError) {
    this.error = error
    this.errorBody = error.body
    this.type = error.body?.error
    this.description = error.body?.error_description
    this.notifications = error.body?.notifications
    this.default = defaultError
  }

  // Cria um array com os erros do backEnd /Notifications ou body
  get errorGetter () {
    const hasMultipleErrors = (this.notifications && Object.keys(this.notifications)?.length)
    const foundErrorsToReduce = this.errorBody?.notifications

    if (hasMultipleErrors) {
      return Object.values(foundErrorsToReduce)
        .reduce((a, b) => [...a, ...b], [])
    }
    return []
  }

  // Assertions
  get _knownErrorsAssertions () {
    return {
      toManyRequest: this.error.status === HTTP_STATUS_CODE.TOO_MANY_REQUESTS,
      mfaNotAllowed: this.description === 'client does not allow mfa user',
      invalidPassword: this.type === 'invalid_grant' || this.description === 'invalid_request',
      userBlocked: this.description === 'user_blocked',
      mfaCodeIsInvalid: this.description === 'mfa code is invalid',
      captchaChallenge: this.description === 'require_captcha_challenge',
    }
  }

  // Retornos
  get mfaNotAllowed () {
    return {
      error: 'PASSWORD.MFA-NOT-ALLOWED',
      needsTranslate: true
    }
  }

  get invalidPassword () {
    return {
      error: 'PASSWORD.INVALID-PASSWORD',
      needsTranslate: true
    }
  }

  get userBlocked () {
    return {
      error: 'PASSWORD.USER-BLOCKED',
      needsTranslate: true
    }
  }

  // mfa code is invalid
  get mfaCodeIsInvalid () {
    return {
      error: 'GENERAL-ERROR.INVALID-OTP',
      needsTranslate: true
    }
  }

  get toManyRequest () {
    return {
      error: 'GENERAL-ERROR.TOO-MANY-REQUESTS',
      needsTranslate: true
    }
  }

  get captchaChallenge () {
    return {
      error: 'GENERAL-ERROR.CAPTCHA-CHALLENGE',
      needsTranslate: true
    }
  }

  // Métodos
  handler () {
    // Passa Pelo Objeto verificando se os erros conhecidos equivalem
    const knownErrorKey = Object.entries(this._knownErrorsAssertions).find(entry => {
      const [key, value] = entry
      if (value) return key
    }) || []

    const getTheKey = knownErrorKey[0] || undefined

    const presetError = [[this[getTheKey]?.error && this[getTheKey]], this.errorGetter, [this.default]]

    // Passa pelo array verificando se tem valor válido
    const validError = presetError.find(el => {
      if (el[0]) return el
    })

    // Método Find Retorna o Entry [key, valor], selecionamos a key
    return validError
  }

  isError (type) {
    return this._knownErrorsAssertions[type] ?? false
  }
}
