import { AbstractAuthError } from './../abstracts/AbstractAuthError'
import CREATE_TOKEN_ERRORS from '@/enums/createTokenErrors.enum'

export class UserBlocked extends AbstractAuthError {
  isMatchError () {
    return this.error_description === CREATE_TOKEN_ERRORS.BLOCKED_USER
  }

  getMessages () {
    return ['PASSWORD.USER-BLOCKED']
  }
}
