import { AbstractAuthError } from './../abstracts/AbstractAuthError'
import CREATE_TOKEN_ERRORS from '@/enums/createTokenErrors.enum'

export class MfaNotAllowed extends AbstractAuthError {

  isMatchError () {
    return this.error_description === CREATE_TOKEN_ERRORS.MFA_NOT_ALLOWED
  }

  getMessages () {
    return ['PASSWORD.MFA-NOT-ALLOWED']
  }
}
