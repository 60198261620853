const dataCore = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
}

const dataCoreProfile = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME,
  callbacksError: {
    unauthorized: () => {
      window.location.assign(process.env.VUE_APP_BASE_URL)
    }
  }
}


export {dataCore, dataCoreProfile}
